//Feedback modul that shows testimonials from customers.
//Add new feedback via the cms
import React from "react"
import "./bara-rent.scss"

// import $ from "jquery"
let $
if (typeof window !== `undefined`) {
  $ = require("jquery")
}

function Feedback(props) {
  return (
    <p>
      {props.feedback} - {props.customer}
    </p>
  )
}

class Feedbacks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.hquery = this.hquery.bind(this)
  }

  //Todo: skriv om till css animation
  hquery = () => {
    $("#paragraphs p").hide()
    doFade("#paragraphs p:first")
    function doFade(elem) {
      $(elem)
        .fadeIn(250)
        .delay(7000)
        .fadeOut(450, function() {
          $(this).insertAfter($("#paragraphs p:last"))
          doFade("#paragraphs p:first")
        })
    }
  }

  componentDidMount() {
    this.hquery()
  }

  render() {
    let Myfeedbacks = this.props.item.customFields.selectedFeedbacks.map(i => {
      this.cname = i.customFields.customer
      this.cfeed = i.customFields.feedback
      return <Feedback customer={this.cname} feedback={this.cfeed} />
    })

    return (
      <section className="section section--full-width" id="feedback">
        <div className="container container--xl featured-hero-reviews">
          <div className="reviews-component bg-white featured-hero-reviews-component">
            <div className="reviews-component-container">
              <div id="paragraphs">{Myfeedbacks}</div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Feedbacks
