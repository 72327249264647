import React from "react"
import { Link } from "gatsby"
import QuickCard from "./QuickCard.js"
import Embeds from "./Embeds.js"
import QuickHeader from "./QuickHeader.js"

// This renders a set of cards for a list of objects. (code incapsulated from ServiceCategory)
// Every objects has to have the properties: Title, Details, Image .
// Also a SitemapNode is required.

const ObjectList = ({ item, providedObjects, dynamicPageItem, primary }) => {
  const renderHTML = html => {
    return { __html: html }
  }

  //If its not a dynamic page, just use the item.
  if (!dynamicPageItem) {
    dynamicPageItem = item
  }

  const footembs = dynamicPageItem.customFields.footEmbeds
  const topembeds = dynamicPageItem.customFields.topEmbeds
  let style = "container-fluid p-3 "

  return (
    <section>
      <QuickHeader
        title={dynamicPageItem.customFields.title}
        image={dynamicPageItem.customFields.articleImage}
      />

      {/* <h1 className="text-center">{dynamicPageItem.customFields.title}</h1> */}
      {topembeds ? (
        <Embeds embeds={dynamicPageItem.customFields.topEmbeds} />
      ) : null}

    { dynamicPageItem.customFields.details ? (
      <div
        className="container centeredText"
        // dangerouslySetInnerHTML={renderHTML(
        //   dynamicPageItem.customFields.details
        // )}
      ><p>{dynamicPageItem.customFields.details}</p></div>
      ) : null }

    { dynamicPageItem.customFields.details ? (
      <div className="centeredText section-spacing-bottom-md">
        <Link to="/kontakt/">
          <button className="text-center btn-lg book-button" id="cmdBook">
            Boka
          </button>
        </Link>
      </div>
      ) : null }

      <div className={ primary ? style += "bg-primary" : style += "bg-secondary" }>
        <div className="container-xxxl ">
          <div className="row justify-content-center">
            <AllObjects providedObjects={providedObjects} />
          </div>
        </div>
      </div>

      {footembs ? (
        <Embeds embeds={dynamicPageItem.customFields.footEmbeds} />
      ) : null}
    </section>
  )
}

const AllObjects = ({ providedObjects }) => {
  return providedObjects.map(objparam => {
    return <QuickCard key={objparam.contentID} providedObject={objparam} />
  })
}

export default ObjectList
