import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "./bara-rent.scss"

let renderHTML = html => {
  return { __html: html }
}

const OfferDetails = ({ item, dynamicPageItem }) => {
  return <Details item={item} dynamicPageItem={dynamicPageItem} />

}

export default OfferDetails

const Details = ({ item, dynamicPageItem }) => {
  const service = dynamicPageItem

  return (
    <div>
      <Header
          title={service.customFields.rubtik}
          image={service.customFields.bild}
        />
      
      <div className="container pt-4 pb-4 text-center">
      <div
          className="font-weight-bold"
          dangerouslySetInnerHTML={renderHTML(service.customFields.ingress)}
        ></div>
      </div>

      <div className="container pt-4 pb-4 text-center grayLink">
        <div
          className="checkerList"
          dangerouslySetInnerHTML={renderHTML(service.customFields.erbjudandetext)}
        ></div>
         <div className="centeredText section-spacing-bottom-md pt-5">
            <Link to="/kontakt/">
              <button className="text-center btn-lg book-button" id="cmdBook">
                Boka
              </button>
            </Link>
            </div>
      </div>
    </div>
  )
}

//export default Details


const Header = ({ title, image }) => {
  return (
    <div>
      {image ? (
        <div className="text-center">
          <meta property="og:image" content={image.url} />
          <ProvidedObjectImage
            image={image}
            label={image ? image.label : ``}
          />
          <Img  src={image.url} 
                alt={image.label} 
                className="offerImg"
                imgStyle={{ objectPosition: 'top' }}/>
          { title ? (
              <h2 className="text-center section-spacing-bottom-md section-spacing-top-md">{title}</h2>
            ) : ( null )}
        </div>
      ) : (  title ? (
        <h2 className="text-center section-spacing-bottom-md section-spacing-top-md">{title}</h2>
      ) : ( null ) 
      )}
    </div>
  )
}

//Put in separate file
const ProvidedObjectImage = ({ image, label }) => {
  let imageToRender = null

  //If it loads from gql
  if (image && image.childImageSharp) {
    imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} />
  }

  //if it loads from item itself
  else if (image && typeof image.url !== "undefined") {
    imageToRender = <img src={image.url} alt={label} className="offerImg" />
  }

  return imageToRender
}