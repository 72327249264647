import React from "react"
import { renderHTML } from "../agility/utils"
import { Link } from "gatsby"
import "./bara-rent.scss"

  const LegalConditions = ({ item }) => {
    return (
        <section className="a4container">
            <div className="A4">
                <h1>Köpvillkor</h1>
                <div className="">
                    <div className="text-center text-black">
                        <div
                        className="container p-3"
                        dangerouslySetInnerHTML={renderHTML(
                            item.customFields.legalText
                        )}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default LegalConditions 