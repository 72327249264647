import React from "react"
import { renderHTML } from "../agility/utils"
import "./bara-rent.scss"

const Footer = () => {
    return (
        <section className="container-fluid bg-dark text-white text-center font-weight-bold footer section-spacing-bottom-md">
            <div className="row align-items-center">
                <div className="col-sm-3 col-12">
                    Bara Rent Sverige AB
                </div>
                <div className="col-sm-6 col-12 text-nowrap">
                    Myggdalsvägen 114, 135 43 Tyresö
                </div>
                <div className="col-sm-3 col-12">
                    Orgnr: 559198-2201
                </div>
            </div>
        </section>
    )
}
export default Footer 