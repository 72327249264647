import React from "react"
import { Link } from "gatsby"
import { cleanHTML } from "../../agility/utils"
import truncate from "truncate-html"
import Img from "gatsby-image"

//Is used to build up the QuickDetails meta-module. Can also be used separately directly in a module. (If you want to use it directly just import it like a normal package)
//Requires a Title, Details and Image field on agilityCMS.
//Embeds are optional.

const QuickCard = ({ providedObject }) => {
  if (!providedObject.sitemapNode) return
  return (
    <div
      key={providedObject.contentID}
      className="col-sm-3 text-center livecolumn cardlink"
    >
      <Link to={fixUrl(providedObject.sitemapNode.pagePath)} >
        <div className="card m-3 border-0 shadow hovercard roundme">
          <ProvidedObjectImage
            image={providedObject.customFields.listingImageLocalImg}
            label={
              providedObject.customFields.listingImage
                ? providedObject.customFields.listingImage.label
                : ``
            }
          />
          <div className="quickCardHeader">
            <h3>{filterTitle(providedObject.customFields.title)}</h3>
          </div>

          {/* <div className="card-body">
            <p className="card-text">
              <ProvidedObjectExcerpt
                htmlContent={providedObject.customFields.details}
              />
            </p>
          </div> */}
        </div>
      </Link>
    </div>
  )
}

const ProvidedObjectImage = ({ image, label }) => {
  let imageToRender = null

  if (image && image.childImageSharp) {
    imageToRender = (
      <Img
        fluid={image.childImageSharp.fluid}
        alt={label}
        className="roundimageUpperBorder"
      />
    )
  }
  return imageToRender
}

const ProvidedObjectExcerpt = ({ htmlContent }) => {
  const renderHTML = () => {
    const excerpt = truncate(cleanHTML(htmlContent), {
      stripTags: true,
      length: 160,
    })
    return { __html: excerpt }
  }
  return <p dangerouslySetInnerHTML={renderHTML()}></p>
}

function filterTitle (title){
  var filteredTitle = title.toString();
  filteredTitle = filteredTitle.replace(" för privatpersoner", "");
  filteredTitle = filteredTitle.replace(" för företag", "");
  filteredTitle = filteredTitle.replace(" för fastighet", "");
  return filteredTitle;
}

function fixUrl (url){
  var fixedUrl = url.toString();

  if (url.includes("-for-privatpersoner")){
    fixedUrl = fixedUrl.replace("/for-fastighet/", "/for-privatpersoner/");
    return fixedUrl;
  }

  if (url.includes("-for-foretag")){
    fixedUrl = fixedUrl.replace("/for-fastighet/", "/for-foretag/");
    return fixedUrl;
  }

  return fixedUrl 
}

export default QuickCard
