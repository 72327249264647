import React from "react"
import Img from "gatsby-image"

const QuickHeader = ({ title, image }) => {
  return (
    <div>
      {image ? (
        <div className="articleHeader ">
          <ProvidedObjectImage
            image={image}
            label={image ? image.label : ``}
          />
          <Img src={image.url} />
          { title ? (
              <h2 className="text-center section-spacing-bottom-md section-spacing-top-md">{title}</h2>
            ) : ( null )}
        </div>
      ) : (  title ? (
        <h2 className="text-center section-spacing-bottom-md section-spacing-top-md">{title}</h2>
      ) : ( null ) 
      )}
    </div>
  )
}

//Put in separate file
const ProvidedObjectImage = ({ image, label }) => {
  let imageToRender = null

  //If it loads from gql
  if (image && image.childImageSharp) {
    imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} />
  }

  //if it loads from item itself
  else if (image && typeof image.url !== "undefined") {
    imageToRender = <img src={image.url} alt={label} />
  }

  return imageToRender
}

export default QuickHeader
