//This module will show a selected subset of questions from the agilityCMS.
import React from "react"
import { graphql, StaticQuery } from "gatsby"
import QuickList from "./helpers/QuickList.js"

//Gör en gql query och returnera en quicklist
export default props => (
  <StaticQuery
    query={graphql`
      query LocationListingModuleQuery {
        allAgilityLocation (
        filter: { properties: { referenceName: { eq: "locations" } } }
        limit: 12
        ) {
          totalCount
          nodes {
            contentID
            customFields {
              title
              details
              articleImageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              listingImageLocalImg {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            properties {
              referenceName
            }
            sitemapNode {
              pagePath
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <QuickList
          {...props}
          providedObjects={queryData.allAgilityLocation.nodes}
          primary={false}
        />
      )
    }}
  />
)
