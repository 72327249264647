import React from "react"
import QuickCard from "./QuickCard.js"

// This renders a set of cards for a list of objects.
// NOT USED FOR RENDERING ENTIRE PAGE - USE QuickListPage instead.
// Every objects has to have the properties: Title, Details, Image .
// Also a SitemapNode is required.

// Use this only if QuickListPage is buggy.

const ObjectList = ({ item, providedObjects }) => {
  const renderHTML = html => {
    return { __html: html }
  }

  return (
    <div>
      <h2 className="text-center">{item.customFields.title}</h2>
      <div
        className="container p-4"
        dangerouslySetInnerHTML={renderHTML(item.customFields.details)}
      ></div>

      <div className="container-fluid bg-primary">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 ">
          <AllObjects providedObjects={providedObjects} />
        </div>
      </div>
    </div>
  )
}

const AllObjects = ({ providedObjects }) => {
  return providedObjects.map(objparam => {
    return <QuickCard key={objparam.contentID} providedObject={objparam} />
  })
}

export default ObjectList
