//This module will show a selected subset of questions from the agilityCMS.
import React from "react"
import { graphql, StaticQuery } from "gatsby"
import QuickList from "./helpers/QuickList.js"

// const QuestionsListing = ({ item, questions }) => {
//   return (
//     <QuickList item={item} providedObjects={questions} />
// <div>
//   <div>
//     <h1 className="text-center">{item.customFields.title}</h1>
//   </div>
//   <div className="bg-primary">
//     <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
//       <Questions questions={questions} />
//     </div>
//   </div>
// </div>
//   )
// }

// const Questions = ({ questions }) => {
//   return questions.map(question => {
//     return <Question key={question.contentID} question={question} />
//   })
// }

// const Question = ({ question }) => {
//   if (!question.sitemapNode) return
//   return (
//     <div key={question.contentID} className="col">
//       <Link to={question.sitemapNode.pagePath} className="text-dark">
//         <div className="card m-3 border-secondary shadow ">
//           <QuestionImage
//             className"card-img-top"
//             image={question.customFields.imageLocalImg}
//             label={
//               question.customFields.image
//                 ? question.customFields.image.label
//                 : `Question Image`
//             }
//           />
//           <div className="card-header">{question.customFields.title}</div>

//           <div className="card-body">
//             {question.customFields.details ? (
//               <p className="card-text">
//                 <QuestionExceprt htmlContent={question.customFields.details} />
//               </p>
//             ) : null}
//           </div>
//         </div>
//       </Link>
//     </div>
//   )
// }

// const QuestionImage = ({ image, label }) => {
//   let imageToRender = null

//   if (image && image.childImageSharp) {
//     imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} />
//   }
//   return imageToRender
// }

// const QuestionExceprt = ({ htmlContent }) => {
//   const renderHTML = () => {
//     const excerpt = truncate(cleanHTML(htmlContent), {
//       stripTags: true,
//       length: 160,
//     })
//     return { __html: excerpt }
//   }
//   return <p dangerouslySetInnerHTML={renderHTML()}></p>
// }

//Gör en gql query och returnera en quicklist
export default props => (
  <StaticQuery
    query={graphql`
      query QuestionListingModuleQuery {
        allAgilityQuestion(
          filter: { properties: { referenceName: { eq: "questions" } } }
          limit: 10
        ) {
          totalCount
          nodes {
            contentID
            customFields {
              title
              question
              details
              articleImage {
                url
                label
              }
              articleImageLocalImg {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              listingImage {
                url
                label
              }
              listingImageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            properties {
              referenceName
            }
            sitemapNode {
              pagePath
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <QuickList
          {...props}
          providedObjects={queryData.allAgilityQuestion.nodes}
          primary={true}
        />
      )
    }}
  />
)
