import React from "react"
import { renderHTML } from "../agility/utils"

const HTMLModule = ({ item }) => {
  const title = item.customFields.title
  const showtitle = item.customFields.showTitle

  let bkg = item.customFields.background
  let containerType

  // if (bkg === "0") {containerType="panel bg_primary p-3"}
  // else if(bkg ==="1") {containerType="panel bg_secondary p-3"}
  // else {containerType="panel p-3"}

  switch (bkg) {
    case "0":
      containerType = "panel bg-primary p-3"
      break
    case "1":
      containerType = "panel bg-secondary p-3"
      break
    default:
      containerType = "panel p-3"
  }

  return (
    <section className="htmlmodule ">
      {title
        ? showtitle === "true" && <h2 className="text-center section-spacing-bottom-md section-spacing-top-md">{title}</h2>
        : null}
      <div className={containerType}>
        <div
          className="container checkerList roundedImages"
          dangerouslySetInnerHTML={renderHTML(item.customFields.hTMLContent)}
        ></div>
      </div>
    </section>
  )
}

export default HTMLModule
