import React from "react"
import QuickDetails from "./helpers/QuickDetails.js"

// let renderHTML = html => {
//   return { __html: html }
// }

const QuestionDetails = ({ item, dynamicPageItem }) => {
  // const service = dynamicPageItem
  // const footembs = service.customFields.footEmbeds
  // const topembeds = service.customFields.topEmbeds

  return <QuickDetails item={item} dynamicPageItem={dynamicPageItem} />

  // <div>
  //   <div className="row articleImageRow">
  //     <div className="col-12">
  //       {service.customFields.image && (
  //         <img
  //           src={service.customFields.image.url}
  //           alt=""
  //           className="mx-auto d-block img-fluid"
  //         />
  //       )}
  //     </div>
  //   </div>

  //   <h1 className="text-center">{service.customFields.title}</h1>
  //   {topembeds ? (
  //     <div className="container-sm-fluid bg-primary pt-4 pb-4">
  //       <Embeds embeds={service.customFields.topEmbeds} />
  //     </div>
  //   ) : null}
  //   <div className="container pt-4 pb-4">
  //     <div
  //       className="checkerList"
  //       dangerouslySetInnerHTML={renderHTML(service.customFields.details)}
  //     ></div>
  //   </div>
  //   {footembs ? (
  //     <div className="container-fluid bg-primary pt-4 pb-4">
  //       <Embeds embeds={service.customFields.footEmbeds} />
  //     </div>
  //   ) : null}
  // </div>
}

export default QuestionDetails
