import React from 'react';
import ContentZone from '../agility/components/ContentZone'

const BaraRentTemplate = (props) => {
    return (
        <div className="">
            <ContentZone name="MainContentZone" {...props} />
        </div>
    );
}
export default BaraRentTemplate;