import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"

/* export default props => (
  <StaticQuery
    query={graphql`
      query MySubheaderQuery {
        agilitySubHeader {
          id
          customFields {
            title
          }
        }
      }
    `}
    render={queryData => {
      return (
        <SubHeader header={queryData.allAgilitySubHeader} {...props} />
      )
    }}
  />
) */

// import $ from "jquery"
let $
if (typeof window !== `undefined`) {
  $ = require("jquery")
}

function Location(props) {
  return (
            <a
                href="/har-finns-vi/"
                target="_self"
                rel=""
                id="linkCity"
                className="text-white"
              >
                {props.title}
            </a>
  )
}

class SubHeader extends Component {
  constructor(props) {
    super(props)
  }

    hquery = () => {
      $.getJSON('https://ipapi.co/json/', function(data) { 
        let city = "";
        //let show = "";
        //console.log(data);

        if (data) {
          if (data.region.toLowerCase().includes("stockholm")) {
            city = "Stockholm";
            //show = { display: "block" };
            $("a#linkCity").attr('href', '/har-finns-vi/stockholm');
          } else if (data.region.toLowerCase().includes("götaland")) {
            city = "Göteborg";
            //show = { display: "block" };
            $("a#linkCity").attr('href', '/har-finns-vi/goteborg');
          }
        } 
        
        if (city != ""){
          let title="Vi finns i " + city + ". Klicka här för vilka områden";
          $("#linkCity").text(title);
        }
        else
        {
          $("#subheader-section").css("display", "none");
        }
      })
    }

  componentDidMount() {
    this.hquery()
  }

  render() {
    let visible = { display: "block" }
    let nonsens = <Location title=''/>
    return (
      <section className="section bg-secondary p-2 p-2" style={visible}>
        <div className="container container--md">
          <div className="text-center my-1">
            <p className="m-0" id="test">
              {nonsens}
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default SubHeader
