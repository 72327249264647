import React from "react"
import emailjs from "emailjs-com"
import { renderHTML } from "../agility/utils"
import "./bara-rent.scss"
import Img from "gatsby-image"
import { graphql, StaticQuery, Link } from "gatsby"

export default props => (
  <StaticQuery
    query={graphql`
    query pageTitleQuery {
      agilityPageTitle {
        customFields {
          title
          image {
            url
            filesize
            pixelHeight
            pixelWidth
            height
            width
          }
        }
      }
    }
    `}
    render={queryData => {
      return (
        <ContactForm form={queryData.allAgilityPageTitle} {...props} />
      )
    }}
  />
)

//Config inställningar för EmailJS
const mailconfig = {
  serviceID: "service_q9nn8nm", 
  templateID: "template_dr776k8", 
  userID: "user_HgKxdKAtdUgCCKADqn94A", 
}

//Put in separate file
const ProvidedObjectImage = ({ image, label }) => {
  let imageToRender = null

  //If it loads from gql
  if (image && image.childImageSharp) {
    imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} />
  }

  //if it loads from item itself
  else if (image && typeof image.url !== "undefined") {
    imageToRender = <img src={image.url} alt={label} />
  }

  return imageToRender
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { title: this.props.item.customFields.title, image: this.props.item.customFields.image, name: "", email: "", tel: "", message: "", formValid: false }

    this.myChangeHandler = this.myChangeHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.isValidForm = this.isValidForm.bind(this)
  }

  myChangeHandler = event => {
    let nam = event.target.name
    let val = event.target.value
    this.setState({ [nam]: val })
  }

  isValidForm = () => {
    let email = this.state.email
    let name = this.state.name
    let message = this.state.message
    let tel = this.state.tel
    if (email && name && message && tel) {
      return true
    } else {
      return false
    }
  }

  handleSubmit = (e) => {
    // HERE: you always want to prevent default, so do this first
    e.preventDefault()

      if (!this.isValidForm()) {
         //message of error in the screen, maybe sweet alerts
         console.log('valideringsfel')
      } else{ 
        this.sendEmail(e)
      }
  };

  //skickar mail med EmailJS
  sendEmail = (e) => {
    emailjs
      .send(
        mailconfig.serviceID,
        mailconfig.templateID,
        this.state,
        mailconfig.userID
      )
      .then(
        result => {        
          console.log("Resultat: " + result.text)
          document.getElementById("contact__btn").disabled = true
          document.getElementById("response").classList.remove("invisible")
          document.getElementById("response").classList.add("visible")

        },
        error => {
          console.log("FEEEL: " + error.text)
        }
      )
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { title, image } = this.state
    return (
        <section className="jumbotron bg-primary pt-4 pb-4 my-0">
            <div>
            {image ? (
              <div className="articleHeader ">
                <ProvidedObjectImage
                  image={image}
                  label={image ? image.label : `ProvidedObject Image`}
                />
                <h2>{title}</h2>
              </div>
            ) : (
              <h2 className="text-center">{title}</h2>
            )}
          </div>

          <div className="container">
          <form method="post"
      action="https://www.flexyform.com/f/5d40602d7a2a13bf6f3b328e0a44be85263ddfc6"
      onSubmit={this.handleSubmit}>
            {/* <form onSubmit={(e) => handleSubmit(e)}> */}
              {/* <label for="InputName1">Namn</label> */}

              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="InputName1"
                  name="name"
                  aria-describedby="nameHelp"
                  placeholder="Ditt namn"
                  value={this.state.name}
                  onChange={this.myChangeHandler}
                  required
                />
              </div>

              <div className="form-group">
                {/* <label for="InputEmail1">Email</label> */}

                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="InputEmail1"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder="Din epost"
                  value={this.state.email}
                  onChange={this.myChangeHandler}
                  required
                />
              </div>

              <div className="form-group">
                {/* <label for="InputTel1">Telefonnummer</label> */}

                <input
                  type="tel"
                  className="form-control form-control-lg"
                  id="InputTel1"
                  name="tel"
                  aria-describedby="telHelp"
                  placeholder="Ditt mobilnummer"
                  value={this.state.tel}
                  onChange={this.myChangeHandler}
                  required
                />
              </div>

              <div className="form-group">
                {/* <label for="InputMessage1">Vad önskar du för hjälp</label> */}

                <textarea
                  rows="3"
                  type="textarea"
                  className="form-control form-control-lg"
                  id="InputMessage1"
                  name="message"
                  aria-describedby="messageHelp"
                  placeholder="Jag behöver hjälp med..."
                  value={this.state.message}
                  onChange={this.myChangeHandler}
                  required
                />
              </div>
              <div className="row">
                <div id="buttonColumn" className="col-sm text-center">
                  <div className="blueLink">
                  Här kan du läsa om våra <Link to="/villkor">köpvillkor</Link>
                  </div>
                  <button
                    type="submit"
                    id="contact__btn"
                    className="btn-lg book-button"
                  >
                    Skicka
                  </button>
                  <div id="response" className="invisible section-spacing-top-md section-spacing-bottom-md">Tack för att du kontaktade oss! Vi hör av oss inom 24 timmar.</div>
                </div>
              </div>
            </form>
          </div>
        </section>
    )
  }
}
