/* verkar inte användas längre */

/* eslint-disable react/display-name */
import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { cleanHTML } from "../agility/utils"
import truncate from "truncate-html"
import Img from "gatsby-image"
//import QuickList from "./helpers/QuickList.js"

export default props => (
  <StaticQuery
    query={graphql`
      query ServiceArticleListingModuleQuery {
        allAgilityServiceArticle(
          limit: 30
        ) {
          totalCount
          nodes {
            contentID
            customFields {
              title
              details

              articleImageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                    ...GatsbyImageSharpFluid
                  }
                }
              } 
              listingImage {
                url
                label
              }
              listingImageLocalImg {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            } 
            sitemapNode {
              pagePath
            }
            properties {
              referenceName
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <ServiceList
          segmentServices={queryData.allAgilityServiceArticle}
          {...props}
        />
      )
    }}
  />
)

const ServiceList = ({ item, segmentServices }) => {
  const footembs = item.customFields.footEmbeds
  const topembeds = item.customFields.topEmbeds
  let style = "container-fluid p-3 "

  return (
    <section>
      <QuickHeader
        title={item.customFields.title + "ghj"}
        image={item.customFields.articleImage}
      />

      {  console.log("!!!!!!!!!!!" + console.log(JSON.stringify(segmentServices)))}
      {topembeds ? (
        <Embeds embeds={item.customFields.topEmbeds} />
      ) : null}

    { item.customFields.details ? (
      <div
        className="container centeredText"
        // dangerouslySetInnerHTML={renderHTML(
        //   dynamicPageItem.customFields.details
        // )}
      ><p>{item.customFields.details}</p></div>
      ) : null }

    { item.customFields.details ? (
      <div className="centeredText section-spacing-bottom-md">
        <Link to="/kontakt/">
          <button className="text-center btn-lg book-button" id="cmdBook">
            Boka!!!!
          </button>
        </Link>
      </div>
      ) : null }

      <div className={ primary ? style += "bg-primary" : style += "bg-secondary" }>
        <div className="container-xxxl ">
          <div className="row justify-content-center">
            <Services providedObjects={providedObjects} />
          </div>
        </div>
      </div>

      {footembs ? (
        <Embeds embeds={dynamicPageItem.customFields.footEmbeds} />
      ) : null}
    </section>
  )
}


const Services = ({ providedObjects }) => {
  return providedObjects.map(objparam => {
    return <QuickCard key={objparam.contentID} providedObject={objparam} />
  })
}

// Ursprung
// const Services = ({ services }) => {
//   return services.map(service => {
//     return <Service key={service.contentID} service={service} />
//   })
// }

// const Service = ({ service }) => {
//   if (!service.sitemapNode) return
//   return (
//     <div className="service" key={service.contentID}>
//       <Link to={service.sitemapNode.pagePath}>
//         <ServiceImage
//           image={service.customFields.imageLocalImg}
//           label={
//             service.customFields.image
//               ? service.customFields.image.label
//               : `Service Image`
//           }
//         />
//         <h2>{service.customFields.title}</h2>
//         <ServiceExceprt htmlContent={service.customFields.details} />
//       </Link>
//     </div>
//   )
// }

// const ServiceImage = ({ image, label }) => {
//   let imageToRender = null

//   if (image && image.childImageSharp) {
//     imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} />
//   }
//   return imageToRender
// }

// const ServiceExceprt = ({ htmlContent }) => {
//   const renderHTML = () => {
//     const excerpt = truncate(cleanHTML(htmlContent), {
//       stripTags: true,
//       length: 160,
//     })
//     return { __html: excerpt }
//   }
//   return <p dangerouslySetInnerHTML={renderHTML()}></p>
// }
