import React from "react"
import QuickDetails from "./helpers/QuickDetails.js"

// let renderHTML = html => {
//   return { __html: html }
// }

const LocationDetails = ({ item, dynamicPageItem }) => {
  return <QuickDetails item={item} dynamicPageItem={dynamicPageItem} />
}

export default LocationDetails
