import React from "react"
import { renderHTML } from "../agility/utils"

const PageTitle = ({ item }) => {
  const title = item.customFields.title
  const image = item.customFields.image
  const mobileImage = item.customFields.mobileImage

  return (
    <section>
        {image ? (
        <div className="articleHeader web">
          {/* <img src={image.url}></img> */}
          <ProvidedObjectImage
            image={image}
            label={image ? image.label : `ProvidedObject Image`}
          />
        </div>
      ) : ( <span ></span>
      )}

      {mobileImage ? (
        <div className="articleHeader mobile">
          {/* <img src={image.url}></img> */}
          <ProvidedObjectImage
            image={mobileImage}
            label={mobileImage ? image.label : `ProvidedObject Image`}
          />
        </div>
      ) : ( <span ></span>
      )}

      <div className="section-spacing-bottom-md">
        { title ? (
          <h1 className="text-center section-spacing-top-md">{title}</h1>
        ) : ( null )}
      </div>
    </section>
  )
}

//Put in separate file
const ProvidedObjectImage = ({ image, label }) => {
  let imageToRender = null

  //If it loads from gql
  if (image && image.childImageSharp) {
    imageToRender = <Img fluid={encodeURI(image.childImageSharp.fluid)} alt={label} />
  }

  //if it loads from item itself
  else if (image && typeof image.url !== "undefined") {
    imageToRender = <img src={encodeURI(image.url)} alt={label} />
  }

  return imageToRender
}

export default PageTitle
