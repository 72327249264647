/* eslint-disable react/display-name */
import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
//import QuickList from "./helpers/QuickList.js"
import QuickHeader from "./helpers/QuickHeader.js"
import Img from "gatsby-image"

export default props => (
  <StaticQuery
    query={graphql`
    query ServiceListingModuleQuery {
      allAgilityServiceCategory(
        filter: { properties: { referenceName: { eq: "servicecategories" } } }
        limit: 12
      ) {
        totalCount
        nodes {
          contentID
          customFields {
            title
            details
            articleImage {
              url
              label
            }
            articleImageLocalImg {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            listingImage {
              url
              label
            }
            listingImageLocalImg {
              childImageSharp {
                fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sitemapNode {
            pagePath
          }
          properties {
            referenceName
          }
        }
      }

      allAgilityServiceArticle {
        totalCount
        nodes {
          contentID
          customFields {
            title
            details
            serviceCategoryID
            articleImage {
              url
            }
            articleImageLocalImg {
              childImageSharp {
                fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            listingImage {
              url,
              label
            }
            listingImageLocalImg {
              childImageSharp {
                fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sitemapNode {
            pagePath
          }
          properties {
            referenceName
          }
        }
      }
    }
    `}
    render={queryData => {
      return (
        <QuickList
          providedObjects={queryData.allAgilityServiceCategory.nodes}
          services={queryData.allAgilityServiceArticle.nodes}
          {...props}
          primary={true}
        />
      )
    }}
  />
)

const QuickList = ({ item, providedObjects, services, dynamicPageItem, primary }) => {
  const renderHTML = html => {
    return { __html: html }
  }

  //If its not a dynamic page, just use the item.
  if (!dynamicPageItem) {
    dynamicPageItem = item
  }

  const footembs = dynamicPageItem.customFields.footEmbeds
  const topembeds = dynamicPageItem.customFields.topEmbeds
  let style = "container-fluid p-3 "

  return (
    <section>
      <QuickHeader
        title={dynamicPageItem.customFields.title}
        image={dynamicPageItem.customFields.articleImage}
      />

      {/* <h1 className="text-center">{dynamicPageItem.customFields.title}</h1> */}
      {topembeds ? (
        <Embeds embeds={dynamicPageItem.customFields.topEmbeds} />
      ) : null}

    { dynamicPageItem.customFields.details ? (
      <div
        className="container centeredText"
        // dangerouslySetInnerHTML={renderHTML(
        //   dynamicPageItem.customFields.details
        // )}
      ><p>{dynamicPageItem.customFields.details}</p></div>
      ) : null }

    { dynamicPageItem.customFields.details ? (
      <div className="centeredText section-spacing-bottom-md">
        <Link to="/kontakt/">
          <button className="text-center btn-lg book-button" id="cmdBook">
            Boka
          </button>
        </Link>
      </div>
      ) : null }

      <div className={ primary ? style += "bg-primary" : style += "bg-secondary" }>
        <div className="container-xxxl ">
          <div className="row justify-content-center">
            <AllObjects 
              providedObjects={providedObjects} 
              services={services}/>
          </div>
        </div>
      </div>

      {footembs ? (
        <Embeds embeds={dynamicPageItem.customFields.footEmbeds} />
      ) : null}
    </section>
  )
}

const AllObjects = ({ providedObjects, services }) => {
  return providedObjects.map(objparam => {
    return <QuickCard key={objparam.contentID} providedObject={objparam} services={services} />
  })
}


const QuickCard = ({ providedObject, services }) => {
  if (!providedObject.sitemapNode) return
  return (
    <div className="col-sm-3">
      <div className="row">
        <div
          key={providedObject.contentID}
          className="col-sm-3 text-center livecolumn cardlink"
        >
          <Link to={fixUrl(providedObject.sitemapNode.pagePath)} >
            <div className="card m-3 border-0 shadow hovercard roundme">
              <ProvidedObjectImage
                image={providedObject.customFields.listingImageLocalImg}
                label={
                  providedObject.customFields.listingImage
                    ? providedObject.customFields.listingImage.label
                    : ``
                }
              />
              <div className="quickCardHeader">
                <h3>{filterTitle(providedObject.customFields.title)}</h3>
              </div>

              {/* <div className="card-body">
                <p className="card-text">
                  <ProvidedObjectExcerpt
                    htmlContent={providedObject.customFields.details}
                  />
                </p>
              </div> */}
            </div>
          </Link>
        </div>
      </div>
      <div className="row pl-4">
        <ul> 
          <SegmentServices 
            segment={providedObject.customFields.title}
            services={services}/>
        </ul>
      </div>
    </div>
  )
}

const SegmentServices = ({ segment, services }) => {
  return services.filter(service => service.customFields.title.includes(segment.toLowerCase()))
    .map(objparam => {
      return <li className="grayLink"><Link to={fixUrl(objparam.sitemapNode.pagePath)}>{filterTitle(objparam.customFields.title)}</Link></li>
  })
}

const ProvidedObjectImage = ({ image, label }) => {
  let imageToRender = null

  if (image && image.childImageSharp) {
    imageToRender = (
      <Img
        fluid={image.childImageSharp.fluid}
        alt={label}
        className="roundimageUpperBorder"
      />
    )
  }
  return imageToRender
}

const ProvidedObjectExcerpt = ({ htmlContent }) => {
  const renderHTML = () => {
    const excerpt = truncate(cleanHTML(htmlContent), {
      stripTags: true,
      length: 160,
    })
    return { __html: excerpt }
  }
  return <p dangerouslySetInnerHTML={renderHTML()}></p>
}

function filterTitle (title){
  var filteredTitle = title.toString();
  filteredTitle = filteredTitle.replace(" för privatpersoner", "");
  filteredTitle = filteredTitle.replace(" för företag", "");
  filteredTitle = filteredTitle.replace(" för fastighet", "");
  return filteredTitle;
}

function fixUrl (url){
  var fixedUrl = url.toString();

  if (url.includes("-for-privatpersoner")){
    fixedUrl = fixedUrl.replace("/for-fastighet/", "/for-privatpersoner/");
    return fixedUrl;
  }

  if (url.includes("-for-foretag")){
    fixedUrl = fixedUrl.replace("/for-fastighet/", "/for-foretag/");
    return fixedUrl;
  }

  return fixedUrl 
}
