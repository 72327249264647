//This renders a details component for cards for a list of objects.
// Every objects has to have the properties: Title, Details, Image
// It can have topembeds , and footembeds (linked content fields).

import React from "react"
import Embeds from "./Embeds.js"
import QuickHeader from "./QuickHeader.js"
import { Link } from "gatsby"


let renderHTML = html => {
  return { __html: html }
}

const QuickDetails = ({ item, dynamicPageItem }) => {
  const service = dynamicPageItem
  const footembs = service.customFields.footEmbeds
  const topembeds = service.customFields.topEmbeds

  return (
    <div>
      {/* <div className="row articleImageRow">
        <div className="col-12">
          {service.customFields.image && (
            <img
              src={service.customFields.image.url}
              alt=""
              className="mx-auto d-block img-fluid"
            />
          )}
        </div>
      </div> */}
      <QuickHeader
          title={service.customFields.title}
          image={service.customFields.articleImage}
        />

      {topembeds ? (
          <Embeds embeds={service.customFields.topEmbeds} />
      ) : null}
      <div className="container pt-4 pb-4">
        <div
          className="checkerList"
          dangerouslySetInnerHTML={renderHTML(service.customFields.details)}
        ></div>
         <div className="centeredText section-spacing-bottom-md pt-5">
            <Link to="/kontakt/">
              <button className="text-center btn-lg book-button" id="cmdBook">
                Boka
              </button>
            </Link>
            </div>
      </div>
      {footembs ? (
          <Embeds embeds={service.customFields.footEmbeds} />
      ) : null}
    </div>
  )
}

export default QuickDetails
