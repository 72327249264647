import React from 'react'
import { Helmet } from "react-helmet"

const TopScript = () => {
    return (
        <Helmet>
           <script src="topScriptJS.js" type="text/javascript" />
        </Helmet>
    )
}

export default TopScript;