import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { cleanHTML } from "../agility/utils"
import truncate from "truncate-html"
// import "./PostListing.css"
import Img from "gatsby-image"
import QuickList from "./helpers/QuickList.js"

export default props => (
  <StaticQuery
    query={graphql`
      query ServiceCategoryDetailsModuleQuery {
        allAgilityServiceArticle {
          totalCount
          nodes {
            contentID
            customFields {
              title
              details
              serviceCategoryID
              articleImage {
                url
              }
              articleImageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              listingImage {
                url,
                label
              }
              listingImageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            sitemapNode {
              pagePath
            }
            properties {
              referenceName
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <ServiceList
          services={queryData.allAgilityServiceArticle.nodes}
          {...props}
        />
      )
    }}
  />
)

const ServiceList = ({ item, services, dynamicPageItem }) => {
  const renderHTML = html => {
    return { __html: html ? html : "Mer info kommer snart" }
  }

  // TODO : Put object in page context instead (and use gql variables with the pagecreate API).
  const correctArticles = services.filter(categorized)

  //Filters down the services. Only those that have the correct CategoryID is shown.
  function categorized(service) {
    return service.customFields.serviceCategoryID
      .split(",")
      .includes(dynamicPageItem.contentID.toString())
  }

  return (
    <QuickList
      item={item}
      providedObjects={correctArticles}
      dynamicPageItem={dynamicPageItem}
    />

    // <div>
    //   <h1 className="text-center">{dynamicPageItem.customFields.title}</h1>
    //   <div className="">
    //     <div className="text-center text-white bg-secondary">
    //       {dynamicPageItem.customFields.details ? (
    //         <div
    //           className="container p-3"
    //           dangerouslySetInnerHTML={renderHTML(
    //             dynamicPageItem.customFields.details
    //           )}
    //         ></div>
    //       ) : null}
    //     </div>
    //     <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 d-flex justify-content-center bg-primary">
    //       <Services services={correctArticles} />
    //     </div>
    //   </div>
    // </div>
  )
}

// const Services = ({ services }) => {
//   return services.map(service => {
//     // if(categories.customFields.serviceCategoryTitle)
//     return <Service key={service.contentID} service={service} />
//   })
// }

// const Service = ({ service }) => {
//   if (!service.sitemapNode) return
//   return (
//     <div key={service.contentID} className="col ">
//       <Link to={service.sitemapNode.pagePath} className="text-dark">
//         <div className="card m-3 ">
//           <ServiceImage
//             image={service.customFields.imageLocalImg}
//             label={
//               service.customFields.image
//                 ? service.customFields.image.label
//                 : `Service Image`
//             }
//           />
//           <div className="card-header">{service.customFields.title}</div>

//           <div className="card-body">
//             <p className="card-text">
//               <ServiceExcerpt
//                 htmlContent={
//                   service.customFields.details
//                     ? service.customFields.details
//                     : `Mer info om denna tjänst kommer snart`
//                 }
//               />
//             </p>
//           </div>
//         </div>
//       </Link>
//     </div>
//   )
// }

// const ServiceImage = ({ image, label }) => {
//   let imageToRender = null

//   if (image && image.childImageSharp) {
//     imageToRender = <Img fluid={image.childImageSharp.fluid} alt={label} />
//   }
//   return imageToRender
// }

// const ServiceExcerpt = ({ htmlContent }) => {
//   const renderHTML = () => {
//     const excerpt = truncate(cleanHTML(htmlContent), {
//       stripTags: true,
//       length: 160,
//     })
//     return { __html: excerpt }
//   }
//   return <p dangerouslySetInnerHTML={renderHTML()}></p>
// }
