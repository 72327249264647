import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import truncate from 'truncate-html'
import { cleanHTML} from '../agility/utils'
import Img from "gatsby-image/withIEPolyfill"
import "./bara-rent.scss"

export default props => (
    <StaticQuery
      query={graphql`
        query OfferListQuery {
            allAgilityOfferList (
                filter: { properties: { referenceName: { eq: "offers" } } }
                limit: 100
                sort: {fields: customFields___publiceratdatum, order: DESC}){
                nodes {
                    customFields {
                        bild {
                            url
                        }
                        bildLocalImg {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                                  ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        erbjudandeslutdatum
                        erbjudandestartdatum
                        erbjudandetext
                        ingress
                        publiceratdatum
                        rubrik
                    }
                    contentID
                    sitemapNode {
                        path
                    }
                    properties {
                        referenceName
                    }
                }
            }
        }
      `}
      render={queryData => {
        return (
            <OfferListing offers={queryData.allAgilityOfferList.nodes} {...props} />
        );
    }}
/>
)

const OfferListing = ({ item, offers }) => {
    return (
        <section className="posts-listing" >
            <div className="container">
                <div className="text-center">
                    <Offers offers={offers} />
                </div>
            </div>
        </section>
    )
}

const Offers = ({ offers }) => {
    return offers.map(offer => {
        return <Offer key={offer.contentID} offer={offer} />;
    })
}

const Offer = ({ offer }) => {
    if (!offer.sitemapNode) return;
    return (
        <div className="row" key={offer.contentID}>
            <div className="col-xs-6 col-lg-2">
                <OfferImage image={offer.customFields.bildLocalImg} label={offer.customFields.bild ? offer.customFields.bild.label : `Erbjudandebild`} />
            </div>
            <div className="col-xs-6 col-lg-10 pt-4 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 offerPost grayLink">            
                            <Link to={offer.sitemapNode.path} className="">
                                <h3>{new Date(offer.customFields.publiceratdatum).toISOString().split('T')[0]} {offer.customFields.rubrik}</h3>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-lg-9 offerPost">
                        {offer.customFields.ingress}
                            {/* <OfferExceprt htmlContent={offer.customFields.ingress} /> */}
                        </div>
                        <div className="col-xs-12 col-lg-3">
                            <div className="d-md-block">
                                <Link to={offer.sitemapNode.path} >
                                    <button className="btn-lg btn-secondary ml-2 text-white" id="cmdBook" >
                                        Se erbjudandet
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const OfferImage = ({ image, label }) => {
    let imageToRender = null;

    if(image && image.childImageSharp) {

        imageToRender = <Img 
                            fluid={image.childImageSharp.fluid} 
                            alt={label} 
                            style={{ position: "center", width: "250px", marginBottom: "30px"}}
                            imgStyle={{ objectPosition: 'top' }}
                            objectFit="contain"
                             /> 
    }
    return imageToRender;
}

const OfferExceprt = ({ htmlContent }) => {
    const renderHTML = () => {
        const excerpt = htmlContent ? truncate(cleanHTML(htmlContent), { stripTags: true, length: 160 }) : '';
        return { __html: excerpt };
    }
    return(<p dangerouslySetInnerHTML={renderHTML()}></p>) 
}
