import React, { Component } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavbarBrand,
  NavLink,
} from "reactstrap"

export default props => (
  <StaticQuery
    query={graphql`
      query GlobalHeaderQuery {
        agilityGlobalHeader(
          properties: { referenceName: { eq: "globalheader" } }
        ) {
          customFields {
            siteName
          }
        }
        allAgilitySitemapNode {
          nodes {
            languageCode
            path
            menuText
            pageID
            visible {
              menu
            }
            }
          }
        }
     
    `}
    render={queryData => {
      const viewModel = {
        item: queryData.agilityGlobalHeader,
        menuLinks: queryData.allAgilitySitemapNode.nodes.filter(sitemapNode => {
          let isTopLevelPage = sitemapNode.path.split("/").length === 2
          const isThisLanguage = sitemapNode.languageCode === props.languageCode
          if (props.isMultiLanguage) {
            isTopLevelPage = sitemapNode.path.split("/").length === 3
          }
          //only return nodes from this language and top level links only
          return isThisLanguage && isTopLevelPage
        }),
      }
      return <GlobalHeader {...viewModel} />
    }}
  />
)

class GlobalHeader extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggleNavbar.bind(this)
    this.state = { isOpen: false }
  }

  renderLinks = () => {
    let links = []

    this.props.menuLinks.forEach(node => {
      if (node.visible.menu) {
        links.push(
          <NavItem className="p-2 text-nowrap navbar-font-size" key={node.pageID}>
            <NavLink href={node.path}>{node.menuText}</NavLink>
          </NavItem>
        )
      }
    })
    
    links.sort((a, b) => a.pageID - b.pageID)
    return links
  }

  toggleNavbar() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (

      <Navbar color="navbar-light" light expand="xl">
        <Container>
          <Link className="navbar-brand" to="/home">
            <img
              src="https://res.cloudinary.com/dxsrki52b/image/upload/v1610973978/BaraRent-logga_b07iug.svg"
              alt="Bara Rent"
              height="35"
            />
          </Link>

          <NavbarToggler onClick={this.toggle} />

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>{this.renderLinks()}</Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}
