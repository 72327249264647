//This module will show a selected subset of questions from the agilityCMS.
import React from "react"
import { graphql, StaticQuery } from "gatsby"
import QuickCard from "./helpers/QuickCard.js"

//Gör en gql query och returnera en quicklist
export default props => (
  <StaticQuery
    query={graphql`
      query QuestionListingPageQuery {
        allAgilityQuestion(
          filter: { properties: { referenceName: { eq: "questions" } } }
          limit: 10
        ) {
          totalCount
          nodes {
            contentID
            customFields {
              title
              question
              details
              articleImage {
                url
                label
              }
              articleImageLocalImg {
                childImageSharp {
                  fluid{
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              listingImage {
                url
                label
              }
              listingImageLocalImg {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 480, maxHeight: 350) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            properties {
              referenceName
            }
            sitemapNode {
              pagePath
            }
          }
        }
      }
    `}
    render={queryData => {
      return (
        <section>
          <div>
            <div className="container-xxxl ">
              <div className="row justify-content-center">
                <AllObjects providedObjects={queryData.allAgilityQuestion.nodes} />
              </div>
            </div>
          </div>
        </section>
      )
    }}
  />
)

const AllObjects = ({ providedObjects }) => {
  return providedObjects.map(objparam => {
    return <QuickCard key={objparam.contentID} providedObject={objparam} />
  })
}
