import React from "react"
import { Link } from "gatsby"
import "../bara-rent.scss"

const Embeds = ({ embeds }) => {
  return embeds.map(embed => {
    return <Embed key={embed.contentID} embed={embed} />
  })
}

const Embed = ({ embed }) => {
  let renderHTML = html => {
    return { __html: html }
  }

  let bkg = embed.customFields.background
  let containerType
  let buttonStyle

  switch (bkg) {
    case "pink":
      containerType = "panel bg-primary p-3"
      buttonStyle = "btn btn-secondary btn-lg text-white shadow"
      break
    case "blue":
      containerType = "panel bg-secondary p-3"
      buttonStyle = "btn btn-primary btn-lg text-black shadow"

      break
    default:
      containerType = "panel p-3"
  }

  return (
    <div className={containerType}>
      {embed.customFields.showTitle === "true" && (
        <h1 className="text-center">{embed.customFields.title}</h1>
      )}

      <div className="container">
        <div
          className="checkerList"
          dangerouslySetInnerHTML={renderHTML(embed.customFields.htmlContent)}
        ></div>

        {embed.customFields.showBookingButton === "true" && (
          <Link to="/kontakt">
            <div className="col-md-12 text-center">
              <button type="link" className={buttonStyle} id="embed__book">
                Boka
              </button>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Embeds
