/* eslint-disable react/prop-types */
import React from "react"
import QuickDetails from "./helpers/QuickDetails.js"






const ServiceDetails = ({ item, dynamicPageItem }) => {
  return <QuickDetails item={item} dynamicPageItem={dynamicPageItem} />
}
export default ServiceDetails
