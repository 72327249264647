import React from "react"
import { renderHTML } from "../agility/utils"
import { Link } from "gatsby"
import "./bara-rent.scss"

const Jumbotron = ({ item }) => {
  return (
    <section className="section bg-primary section--full-width featured-hero">
      <div className="row no-gutters h-100">
        <div className="col-12 col-md-6 bgImg featured-hero-image"></div>
        <div className="col-12 col-md-6 order-md-first">
          <div className="container featured-hero-container" id="jumbo">
            <div className="d-none d-md-block">
              <h1 className="jumbo__title">{item.customFields.title}</h1>
            </div>
            <div
              className="jumbo__subtitle"
              dangerouslySetInnerHTML={renderHTML(item.customFields.subTitle)}
            ></div>
           

            <div className="jumbotron__bookbtn">
              <div className="d-md-block">
                <Link to="/kontakt/">
                  <button className="btn-lg btn-secondary ml-2" id="cmdBook" >
                    Boka
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Jumbotron
